import React from "react";
import Login from "./views/LogIn/LogIn";
import DashBoard from "./views/Dashboard/DashBoard";
import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

//PI_2024_10 Release Version
//Currently independent on backend code.
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route exact path="/connect" element={<DashBoard />} />
          <Route exact path="*" element={<DashBoard />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;